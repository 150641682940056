@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800&display=swap);
/* // Tests
.test-1 {
  @include linear-gradient(#31B7D7, #EDAC7D);
}

.test-2 {
  @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
}

.test-3 {
  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
} */
body {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

h1 {
  font-size: 1.8rem;
  font-weight: 300;
  color: #00293d; }

.main {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto; }

.card {
  box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1); }

.content-header {
  height: 90px;
  background: #fff;
  padding: 0 15px;
  line-height: unset;
  text-align: left;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1); }

.header-login {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.avatar-comp {
  cursor: pointer; }

.ant-dropdown-menu.user-menu {
  background-color: #00293d;
  min-width: 200px;
  margin-top: 3px; }
  .ant-dropdown-menu.user-menu:before {
    content: "";
    position: absolute;
    top: -6px;
    right: 12px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #00293d; }
  .ant-dropdown-menu.user-menu li {
    padding: 10px 12px; }

.ant-form label {
  font-size: 13px; }

@media (max-width: 992px) {
  .lg-hidden {
    display: none; } }

.content-header {
  height: 90px;
  background: #fff;
  padding: 0 15px;
  line-height: unset;
  text-align: left;
  box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1); }
  @media (max-width: 992px) {
    .content-header {
      padding-left: 60px; } }
  .content-header .content-header-row {
    height: 90px; }

.side-nav {
  background: #00293d;
  background: -webkit-gradient(linear, left top, left bottom, from(#00293d), to(rgba(0, 41, 61, 0.9)));
  background: linear-gradient(180deg, #00293d, rgba(0, 41, 61, 0.9)); }
  .side-nav .ant-menu-dark {
    background: transparent; }
  .side-nav .logo {
    height: 90px;
    background: rgba(242, 244, 246, 0.1);
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }
  .side-nav .ant-menu-item {
    padding: 0 !important; }
    .side-nav .ant-menu-item a {
      padding: 0 20px;
      border-left: 2px solid rgba(30, 183, 216, 0); }
    .side-nav .ant-menu-item a.active {
      background-color: #1eb7d8;
      color: #fff; }
  .side-nav .ant-menu-item-active a {
    border-color: #1eb7d8; }
  .side-nav .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .side-nav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent; }
  .side-nav .ant-menu-item .anticon,
  .side-nav .ant-menu-submenu-title .anticon {
    font-size: 16px; }
  .side-nav .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #03273a;
    box-shadow: none; }

.ant-table-body .ant-tag {
  margin: 2px 6px 2px 0; }

.ant-layout-sider-zero-width-trigger {
  top: 22px;
  background: transparent;
  color: #526d74;
  font-size: 22px;
  right: -50px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50px; }
  .ant-layout-sider-zero-width-trigger:hover {
    background: #f2f4f6;
    color: #00293d; }

.ant-card .ant-card-head-title {
  font-weight: 500; }

.ant-card:not(.login-card) .ant-card-head-title {
  color: #1eb7d8;
  font-size: 1.2rem; }

.avatar-wrapper {
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }
  .avatar-wrapper .avatar-link {
    height: 90px;
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end;
    max-width: 150px;
    overflow: hidden;
    color: #526d74;
    padding: 10px; }
    .avatar-wrapper .avatar-link .ant-avatar {
      min-width: 32px;
      margin-right: 4px; }
    @media (min-width: 992px) {
      .avatar-wrapper .avatar-link:hover {
        background-color: rgba(242, 244, 246, 0.5); }
        .avatar-wrapper .avatar-link:hover .ant-avatar {
          box-shadow: 0 0 6px rgba(82, 109, 116, 0.3); } }

.user-menu .anticon {
  float: right;
  padding-top: 4px; }

.login-btns .ant-btn {
  margin-left: 10px; }

.login-card {
  max-width: 380px;
  margin: 0 auto;
  box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1);
  border-radius: 5px; }

.login-error {
  margin-bottom: 15px; }

.login-form-forgot {
  float: right; }

.login-form-button {
  margin-top: 10px;
  width: 100%; }

#content-wrapper {
  margin: 20px; }

.table-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1); }
  .table-card > .ant-table {
    min-height: 300px; }

.orders .clickable {
  cursor: pointer;
  -webkit-transition: background-color 150ms ease;
  transition: background-color 150ms ease; }
  .orders .clickable:hover td:first-of-type {
    color: #1eb7d8; }

.orders .processing {
  font-weight: 600; }

.orders .success,
.orders .default {
  background-color: rgba(242, 244, 246, 0.3); }

.orders .expand-parent {
  background-color: rgba(30, 183, 216, 0.1); }

.orders .order-details-wp {
  padding: 8px; }
  .orders .order-details-wp .ant-spin {
    margin: 50px auto;
    display: block; }

.orders .order-status-badge .ant-badge-status-text {
  font-size: 13px;
  color: #526d74; }

.order-total {
  margin: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
  border-bottom: 1px solid #e8e8e8; }
  .order-total .links-title {
    font-size: 15px;
    display: inline-block;
    padding: 5px 10px;
    margin-top: 10px;
    color: #526d74; }
  .order-total a {
    -webkit-transition: color 200ms ease, background-color 200ms ease;
    transition: color 200ms ease, background-color 200ms ease;
    color: #526d74;
    background-color: #f2f4f6;
    border-radius: 25px;
    padding: 5px 12px 5px 7px;
    display: inline-block;
    margin: 10px 10px 0 0; }
    .order-total a .order-total-count {
      padding: 1px 3px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      border-radius: 20px;
      display: inline-block;
      text-align: center;
      min-width: 20px;
      background-color: rgba(0, 41, 61, 0.4); }
    .order-total a:hover {
      color: #fff;
      background-color: #1eb7d8; }

.ant-table-pagination {
  padding-right: 15px; }

.content-btn-wp {
  text-align: right; }

.submit-btn {
  padding: 0 24px;
  text-align: right; }
  .submit-btn .ant-btn {
    margin-left: 8px; }

.drawer-footer .submit-btn {
  padding: 0; }

.action-column {
  text-align: center; }
  .action-column .delete-icon {
    color: #526d74;
    cursor: pointer; }
    .action-column .delete-icon:hover {
      color: #ff5879; }

.card .spin-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.steps-content {
  padding: 28px 24px; }

.ant-form-item-label {
  text-align: left;
  line-height: 22px; }
  .ant-form-item-label label {
    color: #526d74; }

.form-group-title {
  border-top: #f2f4f6 2px solid;
  font-weight: 600;
  color: #526d74;
  padding: 10px 0 15px 0;
  margin-top: 20px; }

.ant-calendar-picker-clear,
.ant-time-picker-clear {
  opacity: 1; }

.m-8 {
  margin: 8px; }

.droppable-area {
  padding: 8px;
  border-radius: 5px;
  margin-top: 8px;
  border: 2px dashed transparent;
  -webkit-transition: border-color 200ms ease-in-out;
  transition: border-color 200ms ease-in-out; }

.droppable-area.isDraggingOver {
  border-color: rgba(82, 109, 116, 0.2); }

.draggable-item {
  background-color: #fff;
  padding: 8px;
  overflow: hidden;
  -webkit-user-select: "none";
     -moz-user-select: "none";
      -ms-user-select: "none";
          user-select: "none";
  border-radius: 5px;
  border: 1px solid rgba(82, 109, 116, 0.2);
  margin: 0 0 6px 0;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  -webkit-transition-property: border-color, box-shadow, background-color;
  transition-property: border-color, box-shadow, background-color; }

.is-dragging {
  background-color: #fff;
  border-color: #1eb7d8;
  box-shadow: 0 3px 8px 0 rgba(30, 183, 216, 0.2); }

.place-in {
  height: 20px; }

.draggable-item-name {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }

.draggable-item-img {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #f2f4f6;
  overflow: hidden;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }
  .draggable-item-img img {
    max-width: 60px;
    max-height: 60px; }

.draggable-item .draggable-item-preview-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(82, 109, 116, 0.4);
  color: #fff;
  font-size: 18px;
  opacity: 0;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-transition-property: opacity, background-color;
  transition-property: opacity, background-color;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }
  .draggable-item .draggable-item-preview-btn:hover {
    opacity: 1;
    cursor: pointer;
    background-color: rgba(82, 109, 116, 0.8); }

.draggable-item:hover .draggable-item-delete-btn {
  background-color: #f2f4f6; }

.draggable-item:hover .draggable-item-preview-btn {
  opacity: 1; }

.draggable-item .draggable-item-delete-btn {
  border-radius: 25px;
  padding: 6px 10px;
  line-height: 24px;
  font-size: 16px;
  margin-right: 6px;
  border: 1px solid transparent;
  -webkit-transition-property: background-color, color, border-color;
  transition-property: background-color, color, border-color;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }
  .draggable-item .draggable-item-delete-btn:hover {
    color: #ff5879;
    border-color: #ff5879;
    cursor: pointer; }

.modal-img {
  max-width: 100%;
  margin: 0 auto;
  display: block; }

.card-title-field {
  margin: 10px; }
  .card-title-field .ant-input:hover {
    background-color: #f5f5f5; }

.step-style {
  box-shadow: 0px -1px 0 0 #e8e8e8 inset; }

.no-padding > .ant-card-body {
  padding: 0; }

.ant-card-type-inner {
  margin-bottom: 30px; }
  .ant-card-type-inner .ant-card-head-title {
    color: #00293d; }

.ant-steps-navigation {
  background-color: rgba(242, 244, 246, 0.5); }

.formItem-no-margin .ant-form-item {
  margin-bottom: 0; }

.visiableContent {
  display: block; }

.hiddenContent {
  display: none; }

.empty-page {
  height: 100%;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center; }

.time-picker-wp {
  min-width: 128px; }

.date-picker-wp {
  min-width: 290px; }

.date-name-wp {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  min-width: 260px; }

.delete-repeated-btn {
  min-width: 40px; }

.col-grow {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1; }

.repeated-fileds-row {
  border-bottom: 1px solid #e8e8e8;
  padding: 4px 0 6px;
  margin-bottom: 10px; }
  .repeated-fileds-row > .ant-col {
    padding-bottom: 8px; }
  .repeated-fileds-row .ant-form-item {
    margin-bottom: 0; }

/* .repeated-fileds-row:first-of-type {
  border: none;
}
 */
.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 14px;
  margin: 14px 0;
  color: #526d74; }

.ticket-type-wp {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end; }

.ticket-type-item {
  margin: 20px 0 10px 0;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(82, 109, 116, 0.3); }
  .ticket-type-item .ant-card-body {
    padding: 18px;
    padding-bottom: 12px; }
  .ticket-type-item .ant-card-head {
    padding: 0 18px; }
  .ticket-type-item .ant-card-head-title {
    color: #116073;
    font-size: 16px; }
  .ticket-type-item .ant-card-extra,
  .ticket-type-item .ant-card-head-title {
    padding: 14px 0; }
  .ticket-type-item .ant-form-item {
    margin-bottom: 5px; }
  .ticket-type-item .delete-icon {
    color: #526d74;
    border-color: #fff;
    box-shadow: none;
    cursor: pointer; }
    .ticket-type-item .delete-icon:hover {
      border-color: #ff5879;
      color: #ff5879; }
  .ticket-type-item .checkbox-wp {
    padding: 8px 0; }
  .ticket-type-item .ant-divider-horizontal {
    margin: 8px 0; }

.pt-2 {
  padding-top: 2rem; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center; }

.extra-date {
  font-size: 12px;
  text-align: right;
  color: #526d74; }
  .extra-date span {
    display: block;
    font-weight: 600; }

.styled-inner-card {
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 4px rgba(82, 109, 116, 0.3); }
  .styled-inner-card .ant-card-head-title {
    font-weight: 600;
    font-size: 16px;
    color: #116073; }

.sponsor-event-title {
  font-size: 13px;
  padding-bottom: 5px;
  border-bottom: 2px solid #f2f4f6; }

.responsable-card {
  margin-top: 20px;
  margin-bottom: 25px; }
  .responsable-card .ant-card-head-title {
    font-weight: 400;
    font-size: 14px;
    color: #116073; }

.sponsor-event-fields {
  padding: 8px 0;
  border-bottom: 1px solid #f2f4f6; }
  .sponsor-event-fields h4 {
    margin: 0; }
  .sponsor-event-fields:last-of-type {
    border: none;
    padding-bottom: 0; }

.order-details-row {
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 14px; }
  .order-details-row h4 {
    margin-bottom: 0; }

.order-company-name {
  padding: 6px 0; }
  .order-company-name h3 {
    font-weight: bold;
    margin-bottom: 0;
    color: #116073; }

.order-company-type {
  color: #526d74;
  font-size: 13px;
  padding: 0 8px; }

.order-company-link .ant-btn-link {
  padding: 0;
  font-size: 13px; }

.order-switch-col {
  padding-left: 15px; }

.order-participant-ticket {
  color: #526d74;
  font-size: 13px;
  padding-right: 15px;
  border-right: 1px solid #e8e8e8; }

.order-participant-item {
  -webkit-transition: background-color 150ms ease;
  transition: background-color 150ms ease; }
  .order-participant-item:hover {
    background-color: #f2f4f6; }

.chat-window {
  border-radius: 26px;
  padding: 3px;
  margin-top: 2rem;
  background-color: #f2f4f6; }

.chat-head {
  background-color: #fff;
  padding: 8px 8px;
  border-radius: 26px; }

.user-switch {
  padding: 4px 5px 4px 15px; }

.chat-user-name {
  padding: 4px 8px; }
  .chat-user-name h4 {
    margin: 0; }

.chat-board {
  position: relative; }
  .chat-board::before, .chat-board::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    box-shadow: inset 0 6px 8px 0 rgba(242, 244, 246, 0.8); }
  .chat-board::after {
    box-shadow: inset 0 -6px 8px 0 rgba(242, 244, 246, 0.8);
    top: auto;
    bottom: 0; }
  .chat-board .ant-empty-normal {
    padding: 40px 0;
    margin: 0; }

ul.chat-messages {
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
  padding: 15px; }
  ul.chat-messages li {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column; }
    ul.chat-messages li small {
      border-radius: 10px;
      padding: 3px 10px;
      background-color: rgba(82, 109, 116, 0.2); }
    ul.chat-messages li p {
      background-color: #116073;
      color: #fff;
      border-radius: 26px;
      margin-bottom: 0;
      padding: 10px 12px;
      max-width: 500px; }
    ul.chat-messages li .message-date {
      padding: 2px 10px;
      margin-bottom: 10px;
      color: rgba(82, 109, 116, 0.6);
      font-size: 12px; }
      ul.chat-messages li .message-date .anticon {
        margin-right: 8px;
        color: #1eb7d8; }
    ul.chat-messages li.user {
      -moz-align-items: flex-start;
      -ms-align-items: flex-start;
      align-items: flex-start; }
      ul.chat-messages li.user small {
        border-bottom-left-radius: 0; }
      ul.chat-messages li.user p {
        border-top-left-radius: 0; }
    ul.chat-messages li.admin {
      -moz-align-items: flex-end;
      -ms-align-items: flex-end;
      align-items: flex-end; }
      ul.chat-messages li.admin small {
        border-bottom-right-radius: 0; }
      ul.chat-messages li.admin p {
        background-color: #fff;
        color: #116073;
        border-top-right-radius: 0; }

.chat-footer {
  background-color: #fff;
  padding: 6px;
  border-radius: 26px; }

.chat-footer.isSending {
  opacity: 0.6; }

.chat-message-field {
  padding-right: 10px; }
  .chat-message-field .ant-input {
    border: 0;
    resize: none;
    box-shadow: none !important; }

.order-btns-wp {
  margin-top: 10px; }
  .order-btns-wp .ant-btn {
    margin: 5px; }

.drawer-title {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 15px; }

.ant-input-prefix .anticon {
  color: #aaa; }

.drawer-footer {
  box-shadow: 16px 0 15px 13px #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #f2f4f6;
  border-radius: 0 0 4px 4px; }

.vat-nr-field .ant-input-group-addon {
  padding: 0;
  border: 0; }

.vat-nr-fetch-btn {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.vat-nr-wrapper .ant-form-item-children-icon {
  right: 32px !important; }

.price-input {
  text-align: right; }

.location-box,
.contact-box {
  list-style: none;
  padding: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
  height: 180px; }
  .location-box li,
  .contact-box li {
    padding: 6px 12px;
    position: relative; }
    .location-box li small,
    .contact-box li small {
      display: block;
      color: #a8a8a8; }
    .location-box li .location-box-delete,
    .location-box li .contact-box-delete,
    .contact-box li .location-box-delete,
    .contact-box li .contact-box-delete {
      position: absolute;
      padding: 14px 8px;
      width: 36px;
      min-height: 18px;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      -webkit-transition: opacity 200ms ease-in-out;
      transition: opacity 200ms ease-in-out; }
      @media (min-width: 992px) {
        .location-box li .location-box-delete,
        .location-box li .contact-box-delete,
        .contact-box li .location-box-delete,
        .contact-box li .contact-box-delete {
          opacity: 0; } }
    .location-box li:hover,
    .contact-box li:hover {
      background-color: #f0ffff; }
      .location-box li:hover .location-box-delete,
      .location-box li:hover .contact-box-delete,
      .contact-box li:hover .location-box-delete,
      .contact-box li:hover .contact-box-delete {
        opacity: 1; }
  .location-box .location-box-not-found,
  .location-box .contact-box-not-found,
  .contact-box .location-box-not-found,
  .contact-box .contact-box-not-found {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    height: 100%;
    background: none !important; }

.repeated-fields-wp {
  background: #f2f4f6;
  border-radius: 5px;
  padding: 8px; }
  .repeated-fields-wp .ant-collapse {
    border: none;
    margin-bottom: 8px;
    background: #fff;
    box-shadow: 0 1px 8px 0 rgba(82, 109, 116, 0.1); }
    .repeated-fields-wp .ant-collapse .ant-collapse-item .ant-collapse-header {
      color: #526d74;
      line-height: 32px;
      padding: 6px 14px;
      padding-left: 40px; }
    .repeated-fields-wp .ant-collapse .ant-collapse-item:last-child {
      border-bottom: 0; }
  .repeated-fields-wp .repeated-fields-title {
    color: #526d74;
    margin: 5px 5px 10px; }
  .repeated-fields-wp .add-btn {
    width: 100%;
    background: transparent;
    margin: 5px 0; }
  .repeated-fields-wp .delete-button {
    border: none;
    box-shadow: none;
    font-size: 14px; }
    .repeated-fields-wp .delete-button:hover {
      color: #ff5879; }

.provider-form-drawer > .ant-row > .ant-col {
  width: 100% !important; }

.ant-drawer-body {
  margin-bottom: 70px; }

.app-footer {
  text-align: center; }

.app-layout {
  min-height: 100vh; }

