@import "flex";
@import "gradient";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800&display=swap");

$primary-color: #1eb7d8;
$secondary-color: #116073;
$gray-color: #526d74;
$muted-color: #a8a8a8;
$light-color: #f2f4f6;
$light-selected-bg: #f0ffff;
$dark-color: #00293d;
$main-red: #ff5879;
$main-yellow: #feb64d;

$text-color: $gray-color;

$box-shadow: 0 1px 8px 0 rgba($gray-color, 0.1);

$light-box-shadow: 0 1px 4px 0 rgba($gray-color, 0.1);
$light-hoverd-box-shadow: 0 1px 4px 0 rgba($primary-color, 0.3);

$light-border: 1px solid #e8e8e8;

$header-height: 90px;
$border-radius: 5px;

$font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;

$md: 768px;
$lg: 992px;

body {
  font-family: $font-family;
}

h1 {
  font-size: 1.8rem;
  font-weight: 300;
  color: $dark-color;
}
.main {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  box-shadow: $box-shadow;
}
.content-header {
  height: $header-height;
  background: #fff;
  padding: 0 15px;
  line-height: unset;
  text-align: left;
  @include flex-align(center, space-between);
  box-shadow: $box-shadow;
}
.header-login {
  @include flex-align(center, center);
}

.avatar-comp {
  cursor: pointer;
}

.ant-dropdown-menu.user-menu {
  &:before {
    content: "";
    position: absolute;
    top: -6px;
    right: 12px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $dark-color;
  }
  background-color: $dark-color;
  min-width: 200px;
  margin-top: 3px;
  li {
    padding: 10px 12px;
  }
}

.ant-form label {
  font-size: 13px;
}

@media (max-width: $lg) {
  .lg-hidden {
    display: none;
  }
}

.content-header {
  height: $header-height;
  background: #fff;
  padding: 0 15px;
  line-height: unset;
  text-align: left;
  box-shadow: $box-shadow;
  @media (max-width: $lg) {
    padding-left: 60px;
  }
  .content-header-row {
    height: $header-height;
  }
}
.side-nav {
  @include linear-gradient(180deg, $dark-color, rgba($dark-color, 0.9));
  .ant-menu-dark {
    background: transparent;
  }
  .logo {
    height: $header-height;
    background: rgba($light-color, 0.1);
    @include flex-align(center, center);
  }
  .ant-menu-item {
    padding: 0 !important;
    a {
      padding: 0 20px;
      border-left: 2px solid rgba($primary-color, 0);
    }
    a.active {
      background-color: $primary-color;
      color: #fff;
    }
  }
  .ant-menu-item-active a {
    border-color: $primary-color;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: 16px;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #03273a;
    box-shadow: none;
  }
}
.ant-table-body .ant-tag {
  margin: 2px 6px 2px 0;
}
.ant-layout-sider-zero-width-trigger {
  top: 22px;
  background: transparent;
  color: $gray-color;
  font-size: 22px;
  right: -50px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50px;
  &:hover {
    background: $light-color;
    color: $dark-color;
  }
}

.ant-card .ant-card-head-title {
  font-weight: 500;
}
.ant-card:not(.login-card) .ant-card-head-title {
  color: #1eb7d8;
  font-size: 1.2rem;
}

.avatar-wrapper {
  @include flex-align(center, flex-end);
  .avatar-link {
    height: $header-height;
    @include flex-align(center, flex-end);
    max-width: 150px;
    overflow: hidden;
    color: $text-color;
    padding: 10px;
    .ant-avatar {
      min-width: 32px;
      margin-right: 4px;
    }
    &:hover {
      @media (min-width: $lg) {
        background-color: rgba($light-color, 0.5);
        .ant-avatar {
          box-shadow: 0 0 6px rgba($gray-color, 0.3);
        }
      }
    }
  }
}

.user-menu {
  .anticon {
    float: right;
    padding-top: 4px;
  }
}

.login-btns {
  .ant-btn {
    margin-left: 10px;
  }
}
.login-card {
  max-width: 380px;
  margin: 0 auto;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}
.login-error {
  margin-bottom: 15px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  margin-top: 10px;
  width: 100%;
}

#content-wrapper {
  margin: 20px;
}
.table-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: $box-shadow;
  > .ant-table {
    min-height: 300px;
  }
}
.orders {
  .clickable {
    cursor: pointer;
    transition: background-color 150ms ease;
    &:hover td:first-of-type {
      color: $primary-color;
    }
  }
  .processing {
    font-weight: 600;
  }
  .success,
  .default {
    background-color: rgba($light-color, 0.3);
  }
  .expand-parent {
    background-color: rgba($primary-color, 0.1);
  }
  .order-details-wp {
    padding: 8px;
    .ant-spin {
      margin: 50px auto;
      display: block;
    }
  }
  .order-status-badge .ant-badge-status-text {
    font-size: 13px;
    color: $gray-color;
  }
}
.order-total {
  margin: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
  border-bottom: $light-border;
  .links-title {
    font-size: 15px;
    display: inline-block;
    padding: 5px 10px;
    margin-top: 10px;
    color: $gray-color;
  }
  a {
    transition: color 200ms ease, background-color 200ms ease;
    color: $gray-color;
    background-color: $light-color;
    border-radius: 25px;
    padding: 5px 12px 5px 7px;
    display: inline-block;
    margin: 10px 10px 0 0;

    .order-total-count {
      padding: 1px 3px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      margin-right: 8px;
      border-radius: 20px;
      display: inline-block;
      text-align: center;
      min-width: 20px;
      background-color: rgba($dark-color, 0.4);
    }
    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }
}
.ant-table-pagination {
  padding-right: 15px;
}
.content-btn-wp {
  text-align: right;
}
.submit-btn {
  padding: 0 24px;
  text-align: right;
  .ant-btn {
    margin-left: 8px;
  }
}
.drawer-footer .submit-btn {
  padding: 0;
}
.action-column {
  text-align: center;
  .delete-icon {
    color: $gray-color;
    cursor: pointer;
    &:hover {
      color: $main-red;
    }
  }
}

.card {
  .spin-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(#fff, 0.8);
    @include flex-align(center, center);
  }
}

.steps-content {
  padding: 28px 24px;
}

.ant-form-item-label {
  text-align: left;
  line-height: 22px;
  label {
    color: $text-color;
  }
}

.form-group-title {
  border-top: $light-color 2px solid;
  font-weight: 600;
  color: $gray-color;
  padding: 10px 0 15px 0;
  margin-top: 20px;
}

.ant-calendar-picker-clear,
.ant-time-picker-clear {
  opacity: 1;
}
.m-8 {
  margin: 8px;
}

.droppable-area {
  padding: 8px;
  border-radius: 5px;
  margin-top: 8px;
  border: 2px dashed transparent;
  transition: border-color 200ms ease-in-out;
}
.droppable-area.isDraggingOver {
  border-color: rgba($gray-color, 0.2);
}
.draggable-item {
  background-color: #fff;
  padding: 8px;
  overflow: hidden;
  user-select: "none";
  border-radius: 5px;
  border: 1px solid rgba($gray-color, 0.2);
  margin: 0 0 6px 0;
  @include flex-align(center, space-between);
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-property: border-color, box-shadow, background-color;
}
.is-dragging {
  background-color: #fff;
  border-color: $primary-color;
  box-shadow: 0 3px 8px 0 rgba($primary-color, 0.2);
}
.place-in {
  height: 20px;
}
.draggable-item-name {
  @include flex-grow(1);
}
.draggable-item-img {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: $light-color;
  overflow: hidden;
  @include flex-align(center, center);
  img {
    max-width: 60px;
    max-height: 60px;
  }
}
.draggable-item .draggable-item-preview-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($gray-color, 0.4);
  color: #fff;
  font-size: 18px;
  opacity: 0;
  @include flex-align(center, center);
  transition-property: opacity, background-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  &:hover {
    opacity: 1;
    cursor: pointer;
    background-color: rgba($gray-color, 0.8);
  }
}

.draggable-item:hover {
  .draggable-item-delete-btn {
    background-color: $light-color;
  }
  .draggable-item-preview-btn {
    opacity: 1;
  }
}

.draggable-item .draggable-item-delete-btn {
  border-radius: 25px;
  padding: 6px 10px;
  line-height: 24px;
  font-size: 16px;
  margin-right: 6px;
  border: 1px solid transparent;
  transition-property: background-color, color, border-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  &:hover {
    color: $main-red;
    border-color: $main-red;
    cursor: pointer;
  }
}
.modal-img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.card-title-field {
  margin: 10px;
  .ant-input:hover {
    background-color: #f5f5f5;
  }
}
.step-style {
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}
.no-padding > .ant-card-body {
  padding: 0;
}

.ant-card-type-inner {
  .ant-card-head-title {
    color: $dark-color;
  }
  margin-bottom: 30px;
}

.ant-steps-navigation {
  background-color: rgba($light-color, 0.5);
}

.formItem-no-margin .ant-form-item {
  margin-bottom: 0;
}

.visiableContent {
  //visibility: visible;
  display: block;
}
.hiddenContent {
  //visibility: hidden;
  display: none;
}

.empty-page {
  height: 100%;
  @include flex-align(center, center);
}

.time-picker-wp {
  min-width: 128px;
}
.date-picker-wp {
  min-width: 290px;
}
.date-name-wp {
  @include flex-grow(1);
  min-width: 260px;
}
.delete-repeated-btn {
  min-width: 40px;
}
.col-grow {
  @include flex-grow(1);
}

.repeated-fileds-row {
  > .ant-col {
    padding-bottom: 8px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  border-bottom: 1px solid #e8e8e8;
  padding: 4px 0 6px;
  margin-bottom: 10px;
}
/* .repeated-fileds-row:first-of-type {
  border: none;
}
 */

.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 14px;
  margin: 14px 0;
  color: $text-color;
}

.ticket-type-wp {
  @include flex-direction(row-reverse);
  @include justify-content(flex-end);
}
.ticket-type-item {
  margin: 20px 0 10px 0;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba($gray-color, 0.3);
  .ant-card-body {
    padding: 18px;
    padding-bottom: 12px;
  }
  .ant-card-head {
    padding: 0 18px;
  }
  .ant-card-head-title {
    color: $secondary-color;
    font-size: 16px;
  }
  .ant-card-extra,
  .ant-card-head-title {
    padding: 14px 0;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .delete-icon {
    color: $gray-color;
    border-color: #fff;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      border-color: $main-red;
      color: $main-red;
    }
  }
  .checkbox-wp {
    padding: 8px 0;
  }
  .ant-divider-horizontal {
    margin: 8px 0;
  }
}

.pt-2 {
  padding-top: 2rem;
}

.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center;
}

.extra-date {
  font-size: 12px;
  text-align: right;
  color: $gray-color;
  span {
    display: block;
    font-weight: 600;
  }
}

.styled-inner-card {
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 4px rgba($gray-color, 0.3);
  .ant-card-head-title {
    font-weight: 600;
    font-size: 16px;
    color: $secondary-color;
  }
}

.sponsor-event-title {
  font-size: 13px;
  padding-bottom: 5px;
  border-bottom: 2px solid $light-color;
}
.responsable-card {
  margin-top: 20px;
  margin-bottom: 25px;
  .ant-card-head-title {
    font-weight: 400;
    font-size: 14px;
    color: $secondary-color;
  }
}
.sponsor-event-fields {
  h4 {
    margin: 0;
  }
  padding: 8px 0;
  border-bottom: 1px solid $light-color;
  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }
}
.order-details-row {
  border-bottom: $light-border;
  padding: 8px 14px;
  h4 {
    margin-bottom: 0;
  }
}
.order-company-name {
  padding: 6px 0;
  h3 {
    font-weight: bold;
    margin-bottom: 0;
    color: $secondary-color;
  }
}
.order-company-type {
  color: $gray-color;
  font-size: 13px;
  padding: 0 8px;
}
.order-company-link {
  .ant-btn-link {
    padding: 0;
    font-size: 13px;
  }
}
.order-switch-col {
  padding-left: 15px;
}
.order-participant-ticket {
  color: $gray-color;
  font-size: 13px;
  padding-right: 15px;
  border-right: $light-border;
}
.order-participant-item {
  transition: background-color 150ms ease;
  &:hover {
    background-color: $light-color;
  }
}
.chat-window {
  border-radius: 26px;
  padding: 3px;
  margin-top: 2rem;
  background-color: $light-color;
}
.chat-head {
  background-color: #fff;
  padding: 8px 8px;
  border-radius: 26px;
}
.user-switch {
  padding: 4px 5px 4px 15px;
}
.chat-user-name {
  padding: 4px 8px;
  h4 {
    margin: 0;
  }
}
.chat-board {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    box-shadow: inset 0 6px 8px 0 rgba($light-color, 0.8);
  }
  &::after {
    box-shadow: inset 0 -6px 8px 0 rgba($light-color, 0.8);
    top: auto;
    bottom: 0;
  }
  .ant-empty-normal {
    padding: 40px 0;
    margin: 0;
  }
}
ul.chat-messages {
  margin: 0;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;
  padding: 15px;
  li {
    @include flexbox;
    @include flex-direction(column);
    small {
      border-radius: 10px;
      padding: 3px 10px;
      background-color: rgba($gray-color, 0.2);
    }
    p {
      background-color: $secondary-color;
      color: #fff;
      border-radius: 26px;
      margin-bottom: 0;
      padding: 10px 12px;
      max-width: 500px;
    }
    .message-date {
      padding: 2px 10px;
      margin-bottom: 10px;
      color: rgba($gray-color, 0.6);
      font-size: 12px;
      .anticon {
        margin-right: 8px;
        color: $primary-color;
      }
    }
    &.user {
      @include align-items(flex-start);
      small {
        border-bottom-left-radius: 0;
      }
      p {
        border-top-left-radius: 0;
      }
    }
    &.admin {
      @include align-items(flex-end);
      small {
        border-bottom-right-radius: 0;
      }
      p {
        background-color: #fff;
        color: $secondary-color;
        border-top-right-radius: 0;
      }
    }
  }
}
.chat-footer {
  background-color: #fff;
  padding: 6px;
  border-radius: 26px;
}
.chat-footer.isSending {
  opacity: 0.6;
}
.chat-message-field {
  padding-right: 10px;
  .ant-input {
    border: 0;
    resize: none;
    box-shadow: none !important;
  }
}
.order-btns-wp {
  margin-top: 10px;
  .ant-btn {
    margin: 5px;
  }
}

.drawer-title {
  border-bottom: $light-border;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.ant-input-prefix .anticon {
  color: #aaa;
}

.drawer-footer {
  box-shadow: 16px 0 15px 13px #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: $light-color;
  border-radius: 0 0 4px 4px;
}

.vat-nr-field .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.vat-nr-fetch-btn {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.vat-nr-wrapper .ant-form-item-children-icon {
  right: 32px !important;
}

.price-input {
  text-align: right;
}

.location-box,
.contact-box {
  list-style: none;
  padding: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
  height: 180px;
  li {
    padding: 6px 12px;
    position: relative;
    small {
      display: block;
      color: $muted-color;
    }
    .location-box-delete,
    .contact-box-delete {
      @media (min-width: $lg) {
        opacity: 0;
      }
      position: absolute;
      padding: 14px 8px;
      width: 36px;
      min-height: 18px;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      transition: opacity 200ms ease-in-out;
    }
    &:hover {
      .location-box-delete,
      .contact-box-delete {
        opacity: 1;
      }
      background-color: $light-selected-bg;
    }
  }
  .location-box-not-found,
  .contact-box-not-found {
    @include flex-align(center, center);
    height: 100%;
    background: none !important;
  }
}

.repeated-fields-wp {
  background: $light-color;
  border-radius: $border-radius;
  padding: 8px;
  .ant-collapse {
    border: none;
    margin-bottom: 8px;
    background: #fff;
    box-shadow: $box-shadow;
    .ant-collapse-item {
      .ant-collapse-header {
        color: $gray-color;
        line-height: 32px;
        padding: 6px 14px;
        padding-left: 40px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .repeated-fields-title {
    color: $gray-color;
    margin: 5px 5px 10px;
  }
  .add-btn {
    width: 100%;
    background: transparent;
    margin: 5px 0;
  }
  .delete-button {
    border: none;
    box-shadow: none;
    font-size: 14px;
    &:hover {
      color: $main-red;
    }
  }
}
.provider-form-drawer {
  > .ant-row > .ant-col {
    width: 100% !important;
  }
}

.ant-drawer-body {
  margin-bottom: 70px;
}
.app-footer {
  text-align: center;
}

.app-layout {
  min-height: 100vh;
}
